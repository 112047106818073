<template>
    <div class="Firmwareupdate full-height pa-4 d-flex flex-column ">
        <div v-if="showOverlay" class="loaderOverlay full-height d-flex flex-column align-center justify-center pb-6">
            <div class="message pb-6">
                <p>{{firmwareprogress.activity}}</p>
            </div>
            <v-progress-linear
                color="disiIrisblue"
                :value=firmwareprogress.progress
                background-color="disiDarkblue"
                height="12"
                buffer-value="0"
                stream
            ></v-progress-linear>
        </div>
        <div class="d-flex full-height-footerButtons">
            <div class="content-area pa-5 flex-grow-1 bg-white">
                <v-row class="d-flex align-center flex-grow-0">
                    <v-col class="col-2">
                        <p>{{ $t('system.firmwareupdate.firmwareVersion') }}</p>
                    </v-col>
                    <v-col class="col-10">
                        <p class="readOnly">{{ firmwarelist.currentFirmwareVersion }}</p>
                    </v-col>
                </v-row>
                <v-row v-if="firmwarelist.usbAvailable" class="d-flex align-center flex-grow-0">
                    <v-col class="col-2">
                        <p>{{ $t('system.firmwareupdate.latestversion') }}</p>
                    </v-col>
                    <v-col class="col-10">
                        <p class="readOnly">{{ firmwarelist.newFirmwareVersion }}</p>
                    </v-col>
                </v-row>
                <v-row v-else class="d-flex align-center justify-center flex-grow-1 pb-4 mt-9">
                    <v-col class="col-6">
                        <p class="text-center pb-4">{{ $t('system.firmwareupdate.insertUSB') }}</p>
                        <v-btn v-ripple class="content-btn" elevation="0" @click="checkForUSB">
                            {{ $t('system.firmwareupdate.checkForUSB') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button buttonFunction="back" @footerButtonClick="goToSystem"></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="update" :successCheck="showUpdateSuccess" :progress="showUpdateProgress" :class="{'disabled': !firmwarelist.usbAvailable || !checkUserRights('firmwareupdateStart')}" @footerButtonClick="update"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'Firmwareupdate',
    components: {
        FooterButton,
    },
    props: {
    },
    data () {
        return{
            showOverlay: false,
            showUpdateProgress: false,
            showUpdateSuccess: false,
        }
    },
    created() {
        this.$store.dispatch('getAxiosSetter', ['/admin/device/firmwarelist', 'firmwarelist']);
    },
    computed: {
        ...mapState([
            'firmwarelist',
            'firmwareprogress',
        ]),
    },
    methods:{
        goToSystem(){
            this.$router.push('system');
        },
        checkForUSB(){
            this.$store.dispatch('getAxiosSetter', ['/admin/device/firmwarelist', 'firmwarelist']);
        },
        getProgress(){
            this.$store.dispatch('getAxiosSetter', ['/admin/device/firmwareprogress', 'firmwareprogress'])
            .then(response=>{
                if (response.status === 200) {
                    if (response.data.activity && this.$router.currentRoute.name == 'Firmwareupdate') {
                        if (response.data.progress < 100) {
                            setTimeout(() => {
                                this.getProgress();
                            }, 500)
                        } else {
                            this.$store.dispatch('getAxiosSetter', ['/admin/device/firmwarelist', 'firmwarelist'])
                            this.showOverlay = false;
                            this.showUpdateProgress = false;
                            this.showUpdateSuccess = true;
                            setTimeout(() => {
                                this.showUpdateSuccess = false;
                            }, 1600)
                        }
                    } else {
                        this.showOverlay = false;
                        this.showUpdateProgress = false;
                    }
                } else {
                    this.showOverlay = false;
                    this.showUpdateProgress = false;
                }
            })
        },
        update(){
            this.showOverlay = true;
            this.showUpdateProgress = true;
            this.$store.dispatch('getAxiosNoSetter', '/admin/device/firmwareupdate')
                .then(response=>{
                    if (response.status === 200) {
                        this.getProgress();
                    } else {
                        this.showOverlay = false;
                        this.showUpdateProgress = false;
                    }
                })
        }
    },
    mixins: [mixins],
    beforeRouteLeave (to, from, next) {
        if(!this.showOverlay){
            next()
        } else {
            this.$confirm({message: this.$t('alerts.updateInProgress'), show: true, okButton: true })
                .then(() => {
                    // continue
                    next();
                })
                .catch(() => {
                    // cancel
                });
        }
    },
}
</script>
<style scoped lang="scss">
.v-application .content-btn:hover{
    background-color: $disiIrisblue;
}
</style>